* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", Verdana, sans-serif;
  letter-spacing: 0.5px;
}

main {
  transition: all 0.8s ease-in-out;
}

:root {
  --overlay: #f0ffffe8;
  --dark-color: #1b1b1b;
  --white: #f5f5f6;
  --dark-blue: #1932c2;
  --blue: #3b59f9;
  --light-blue: #a9b7ff;
  --transition: all 0.3s ease-in-out;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
} */

.toggle-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.theme-btn {
  outline: none;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
}

.lang-btn {
  width: 22px;
  height: 22px;
}

/* =============================== responsive ======================== */

@media only screen and (max-width: 767px) {
  .toggle-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  .theme-btn {
    outline: none;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    margin: 1rem;
  }

  .lang-btn {
    width: 22px;
    height: 22px;
    margin: 1rem;
  }
}
