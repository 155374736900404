/* main title */

hr.solid {
  border: 0;
  height: 5px;
  background: var(--blue);
  background: linear-gradient(165deg, #3956f9 0%, #a9b7ff 68%);
}

/* hero */

.title {
  background-color: var(--dark-color) !important;
  color: #f0ffff;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  padding-left: 10rem;
  position: relative;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: azure;
}
.innerHero {
  height: 50vh;
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}

.display-3 {
  margin: 0 auto;
  position: relative;
  width: max-content;
  color: azure;
}

.display-3::before,
.display-3::after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  animation: typewriter 5s steps(18) 1s forwards;
}

.display-3::before {
  background: var(--dark-color);
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: var(--dark-color);
  }
}

.display-3::after {
  width: 0.3rem;
  background: var(--white);
  animation: typewriter 5s steps(18) 1s forwards, blink 1s steps(18) infinite;
}

.hero-arrow {
  cursor: pointer;
  transform: translateY(90%);
  animation: fadeIn 13s;
}

.hero-arrow img {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 5s infinite 5s;
}

@keyframes fadeIn {
  0%,
  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/* intro section */

.intro-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 500px;
  height: 500px;
}

.intro-img img {
  width: 100%;
  height: 100%;
}

/*navbar*/
.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar {
  background-color: rgb(23, 23, 24);
  color: #fff;
  width: 100%;
}
.nav-item {
  display: flex;
  align-items: center;
}

.nav-links {
  background-color: rgb(23, 23, 24);
  color: #fff !important;
  transition: var(--transition);
  text-decoration: none;
}

.activePath:hover {
  text-decoration: none;
}

.nav-links:hover, .activePath {
  background-color: rgb(23, 23, 24);
  color: var(--blue) !important;
  text-decoration: none;
  border-bottom: 3px solid var(--blue);
  text-decoration: none;
  transform: scale(1.1);
}

/* Resources */

.resources-box {
  width: 100%;
  min-height: 400px;
  margin: 8px;
}

.resource-tag {
  width: auto !important;
}
.resource-tag {
  width: 55px !important;
  background-color: #a9b7ff;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
}

.resources-button {
  background: linear-gradient(178.23deg, #3b59f9 1.5%, #a9b7ff 128.18%);
  border-radius: 50px;
  color: var(--white);
  transition: var(--transition);
  text-transform: capitalize;
}
.resources-button:hover {
  text-decoration: none;
  color: var(--white);
  transform: scale(1.05)!important;
  box-shadow: 0px 2.5px 2.5px #a2bae1;
}

.resource-description {
  color: var(--dark-color);
}

/* footer*/

.footer {
  background-color: var(--dark-color);
  color: #f0ffff;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  padding: 4rem 0;
  /* position: relative; */
  text-align: center;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-title {
  color: #f0ffff;
}

footer .socialIcon {
  display: "flex";
  margin: 1.5rem 1.5rem 0;
  transition: var(--transition);
}
footer .socialIcon:hover {
  color: var(--white);
  transform: scale(1.2);
}

/*Hero Title No Typewriter Effect*/

.hero-title {
  margin: 0 auto;
  position: relative;
  width: max-content;
  /* color: azure; */
}

/*Tech Paths*/
.techpaths-title {
  margin: 0 auto;
  position: relative;
  width: max-content;
  color: #3956f9;
}

/*Resources*/
.resources-title {
  margin: 0 auto;
  position: relative;
  width: max-content;
  color: #3956f9;
}

.meet-team {
  padding-bottom: 8rem;
}

.teamCard {
  background-color: transparent !important;
  border: none !important;
}
.teamCard h4 {
  background-color: transparent !important;
  border: none !important;
  font-weight: bold;
}

/* Loading component */

@keyframes ldio-lq4tge5btbc {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-lq4tge5btbc div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-lq4tge5btbc linear 1s infinite;
  background: #0e2dc6;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-lq4tge5btbc div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #0e2dc6;
}.ldio-lq4tge5btbc div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #444ff0;
}.ldio-lq4tge5btbc div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #7184d2;
}.ldio-lq4tge5btbc div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #a2bae1;
}.ldio-lq4tge5btbc div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #cedbf4;
}.ldio-lq4tge5btbc div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #e8edff;
}.ldio-lq4tge5btbc div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #0e2dc6;
}.ldio-lq4tge5btbc div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #444ff0;
}.ldio-lq4tge5btbc div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #7184d2;
}.ldio-lq4tge5btbc div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #a2bae1;
}.ldio-lq4tge5btbc div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #cedbf4;
}.ldio-lq4tge5btbc div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #e8edff;
}
.loadingio-spinner-spinner-3gbg8ycpwq8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-lq4tge5btbc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-lq4tge5btbc div { box-sizing: content-box; }
/* generated by https://loading.io/ */


/* responsive styles */

@media only screen and (max-width: 767px) {
  .title h1 {
    font-size: 2.2rem;
  }

  .title p {
    margin-top: 20px;
    font-size: 1rem;
  }

  .hero-arrow img {
    width: 50px;
  }

  .nav-item {
    justify-content: center;
    margin-bottom: 2rem;
  }

  /* nav .nav-links {
    margin-bottom: 2rem;
    padding: 10px 0;
  } */
}
